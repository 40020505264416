import React, {Component} from 'react';
import axios from "axios";
import {connect} from "react-redux";
import './Category.css';
import StoryItem from "../Library/StoryItem/StoryItem";
import Slider from "react-slick";
import {Link} from "react-router-dom";

class Category extends Component{

    state = {
        title:'',
        stories:[],
        genres:['Adventure', 'Crime', 'Drama', 'Fantasy', 'Horror', 'Humor', 'Mystery', 'Non Fiction', 'Romance', 'Science Fiction', 'Thriller', 'Teen Novel'],
        urlGenres:['adventure', 'crime', 'drama', 'fantasy', 'horror', 'humor', 'mystery', 'non-fiction', 'romance', 'science-fiction', 'thriller', 'teen-novel'],
        showPageFlag:false
    };

    async componentDidMount() {
        this.loadPage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.category !== this.props.match.params.category) {
            this.loadPage();
        }
    }

    async loadPage(){
        this.props.showLoading();
        const params = this.props.match.params;
        if(params.category==='most-read'){
            await this.getMostRead();
        }else if(params.category==='most-interacted'){
            await this.getMostInteracted();
        }else if(params.category==='recently-published'){
            await this.getRecentlyPublished();
        }else{
            if(this.state.urlGenres.includes(params.category)){
                await this.getByGenre(params.category);
            }else{
                this.props.hideLoading();
                this.props.history.push('/');
            }
        }
    }

    getMostRead(){
        axios.post(`${process.env.REACT_APP_SERVER_IP}/custom-activities/top-read-stories`,{
            limit:25,
            skip:0
        }).then(
            (response)=>{
                //console.log("SUCCESS GETTING TOP READ",response);
                this.props.hideLoading();
                if(response.data.status===1){
                    this.setState({...this.state,title:'MOST READ STORIES',stories:response.data.data});
                }else{

                }
            },
            (error)=>{
                this.props.hideLoading();
                //console.log("GET TOP READ ERROR",error);
            }
        );
    }

    getMostInteracted(){
        axios.post(`${process.env.REACT_APP_SERVER_IP}/custom-activities/top-interacted-stories`,{
            limit:25,
            skip:0
        }).then(
            (response)=>{
                //console.log("SUCCESS GETTING TOP INTERACTED",response);
                this.props.hideLoading();
                if(response.data.status===1){
                    this.setState({...this.state,title:'MOST INTERACTED STORIES',stories:response.data.data});
                }else{

                }
            },
            (error)=>{
                this.props.hideLoading();
                //console.log("GET TOP INCTERACTED ERROR",error);
            }
        );
    }

    getRecentlyPublished(){
        axios.post(`${process.env.REACT_APP_SERVER_IP}/custom-activities/recently-published-stories`,{
            limit:25,
            skip:0
        }).then(
            (response)=>{
                //console.log("SUCCESS GETTING RECENTLY PUBLISHED",response);
                this.props.hideLoading();
                if(response.data.status===1){
                    this.setState({...this.state,title:'RECENTLY PUBLISHED STORIES',stories:response.data.data});
                }else{

                }
                this.setState({
                    ...this.state,
                    showPageFlag: true
                });
            },
            (error)=>{
                this.props.hideLoading();
                //console.log("GET RECENTLY PUBLISHED ERROR",error);
            }
        );
    }

    getByGenre(genre){
        const searchGenre = this.filterGenre(genre);
        axios.post(`${process.env.REACT_APP_SERVER_IP}/custom-activities/by-genre-stories`,{
            limit: 25,
            skip: 0,
            genre:searchGenre
        }).then(
            (response)=>{
                //console.log("SUCCESS GETTING RECENTLY PUBLISHED",response);
                this.props.hideLoading();
                if(response.data.status===1){
                    this.setState({...this.state,title:`STORIES IN ${genre}`,stories:response.data.data});
                }else{

                }
                this.setState({
                    ...this.state,
                    showPageFlag: true
                });
            },
            (error)=>{
                this.props.hideLoading();
                //console.log("GET RECENTLY PUBLISHED ERROR",error);
            }
        );
    }

    filterGenre(genre){
        let spaces = genre.replace(/-/g, " ");
        return spaces[0].toUpperCase()+spaces.slice(1).toLowerCase();
    }

    openGenre(genre){
        this.props.history.push(`/library/${genre}`);
    }

    render() {
        const stories = this.state.stories.map((story,i)=>{
            return <div style={{ padding:'0px' }} className="category-col col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" key={i}><StoryItem story={story} /></div>;
        });

        const genres = this.state.urlGenres.map((genre,i)=>{
            return <span className="genre-item" onClick={()=>{ this.openGenre(genre) }} key={i}>{genre}</span>;
        });

        const settings = {
            dots: false,
            arrows:false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 5,
            variableWidth: true
        };

        return (
            <div className="app-page site-stories-wrap lib-page-wrap">
                <div className="library-alpha-notice">
                    The Tellry library is still in alpha testing version, therefor you might see many gibberish stories here :)
                    <span className="hide-library-alpha-notice center">
                        <span className="center">x</span>
                    </span>
                </div>
                <div className="app-title lib-title category-title">
                    <div className="lib-inner-title"><div>{this.state.title}</div></div>
                    <div className="lib-genres-slider">
                        <Slider {...settings}>
                            {genres}
                        </Slider>
                    </div>
                </div>
                <div className="category-results-wrap library-search-results row">
                    {stories}
                    { this.state.stories.length===0 ? (<div className="category-no-results center">
                        <div>NO RESULTS</div>
                        <Link to="/library"><div className="app-button">TO LIBRARY</div></Link>
                    </div>) : null }
                </div>
                {/*<div className="library-loading-wrap">*/}
                {/*    <img alt="Loading..." className="library-loading-web" src={require('../../../assets/images/lib-results-loading-web.svg')} />*/}
                {/*    <img alt="Loading..." className="library-loading-mob" src={require('../../../assets/images/lib-results-loading-mobile.svg')} />*/}
                {/*</div>*/}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLoading: ()=>{
            dispatch({
                type:'SHOW_LOADING'
            });
        },
        hideLoading: ()=>{
            dispatch({
                type:'HIDE_LOADING'
            });
        }
    }
};

export default connect(null,mapDispatchToProps)(Category);
