import React, {Component} from 'react';
import './About.css';
import Slider from "react-slick";
import {Link} from 'react-router-dom';

class About extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    render() {
        document.title = `Tellry - About Us`;

        const screensSliderSettings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 540,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
            ]
        };

        return (
            <div className="app-page about-wrap">
                <div className="about-section row">
                    <img alt="About Background" className="about-section-bg-1 center" src={require('../../../assets/images/about-bg-1.svg')} />
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <h2 className="app-title">WHAT IS TELLRY?</h2>
                        <div className="about-cont-1">
                            Tellry is a mobile app for social story writing that allows users to co-write and share stories. Write a story with your friends or get quickly matched with random writers from around the world. Write in multiple entertaining story modes!
                            <br/><br/>
                            The Application is currently beginning the Alpha testing stage and the first Open Beta is planned to launch within a month. The production version will hopefully be ready before the end of 2020.
                            <br/><br/>
                            If you are interested in becoming an alpha tester please <Link to="/contact">contact us</Link> via private message and submit a request to or <a href="https://www.facebook.com/groups/818701652002557" target="_blank" rel="noopener noreferrer" >facebook group</a>
                        </div>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="about-vid">
                            <img alt="About Video" className="about-vid-pc" src={require('../../../assets/images/comp.svg')} />
                            <img alt="About Video Play" className="about-vid-play center" src={require('../../../assets/images/play.svg')} />
                        </div>
                        <div className="about-slider">
                            <Slider ref={slider => (this.slider = slider)} {...screensSliderSettings}>
                                <img alt="Tellry App Screen 1" src={require('../../../assets/images/app-screen-1.png')} />
                                <img alt="Tellry App Screen 2" src={require('../../../assets/images/app-screen-2.png')} />
                                <img alt="Tellry App Screen 3" src={require('../../../assets/images/app-screen-3.png')} />
                                <img alt="Tellry App Screen 4" src={require('../../../assets/images/app-screen-4.png')} />
                                <img alt="Tellry App Screen 5" src={require('../../../assets/images/app-screen-5.png')} />
                                <img alt="Tellry App Screen 6" src={require('../../../assets/images/app-screen-6.png')} />
                                <img alt="Tellry App Screen 7" src={require('../../../assets/images/app-screen-7.png')} />
                                <img alt="Tellry App Screen 8" src={require('../../../assets/images/app-screen-8.png')} />
                            </Slider>
                            <img alt="Next Slide" className="about-slider-arrow asa-right center" src={require('../../../assets/images/arrow-right.svg')} onClick={this.next}  />
                            <img alt="Previous Slide" className="about-slider-arrow asa-left center" src={require('../../../assets/images/arrow-left.svg')} onClick={this.previous} />
                        </div>
                    </div>
                </div>
                <div className="about-section row">
                    <div className="col-12">
                        <h2 className="app-title about-title-2">WHAT CAN YOU DO IN TELLRY?</h2>
                        <div className="about-infographic">
                            <img className="about-infographic-web" alt="What to do in Tellry infographic" src={require('../../../assets/images/about-infographic.svg')} />
                            <img className="about-infographic-mob" alt="What to do in Tellry infographic" src={require('../../../assets/images/about-infographic-mob.svg')} />
                            <div className="about-infographic-text ait-1">Create you personal writer profile</div>
                            <div className="about-infographic-text ait-2">Get quickly matched with a random co-writer</div>
                            <div className="about-infographic-text ait-3">Write a story together</div>
                            <div className="about-infographic-text ait-4">Publish you completed stories to the Tellry library</div>
                            <div className="about-infographic-text ait-5">Enjoy reading other writers creations</div>
                        </div>
                    </div>
                </div>

                <h2 className="app-title about-story-modes-title">WHAT STORY MODES ARE AVAILABLE?</h2>

                <div className="about-section about-story-section row">
                    <img alt="About section background" className="about-section-bg-2 center" src={require('../../../assets/images/about-bg-2.svg')} />
                    <div className="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-2 order-xs-2 order-sm-2 order-md-1 order-lg-1 order-xl-1">
                        <img alt="Tellry Quick Story Mode Screen" src={require('../../../assets/images/app-screen-quick.svg')} />
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-1 order-xs-1 order-sm-1 order-md-2 order-lg-2 order-xl-2" style={{ position:'relative' }}>
                        <div className="about-story-section-cont center">
                            <h3>SHORT - QUICK STORY MODE</h3>
                            <ul>
                                <li>Invite a friend or join a matching que</li>
                                <li>The story lasts for 10 rounds, each writer adds an entry and ends his/her turn</li>
                                <li>Each input has a maximum of 1000 chars</li>
                                <li>You can chat with your co-writer via a private story chat</li>
                                <li>After the story is finished you can publish it to your profile or share with friends, the story will be also visible on the Tellry website but only to people with a share link - its is not shared to the Tellry public library</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="about-section about-story-section row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" style={{ position:'relative' }}>
                        <div className="about-story-section-cont center">
                            <h3>SHORT - BLIND STORY MODE</h3>
                            <ul>
                                <li>Same exact rules like in the quick mode, with a few differences:</li>
                                <li>Entries are limited to 220 characters</li>
                                <li>You can only see the last 60 characters of your co-writers input, making the writing process more funny and unexpected</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                        <img alt="Tellry Blind Story Mode Screen" src={require('../../../assets/images/app-screen-blind.svg')} />
                    </div>
                </div>

                <div className="about-section about-story-section row">
                    <img alt="About section background" className="about-section-bg-3 center" src={require('../../../assets/images/about-bg-3.svg')} />
                    <div className="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-2 order-xs-2 order-sm-2 order-md-1 order-lg-1 order-xl-1">
                        <img alt="Tellry Custom Story Mode Screen" src={require('../../../assets/images/app-screen-custom.svg')} />
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 order-1 order-xs-1 order-sm-1 order-md-2 order-lg-2 order-xl-2" style={{ position:'relative' }}>
                        <div className="about-story-section-cont center">
                            <h3>CUSTOM MODE</h3>
                            <ul>
                                <li>You have total creative freedom, write yourself, with friends or allow Tellry users to apply as writers</li>
                                <li>Manage user permissions, who can edit, who can delete, who can invite other users, and much more</li>
                                <li>Add entries, manage entry revisions and see the changes your co-writers are making</li>
                                <li>When you are done - publish your story to the public Tellry library and share it with the world.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="about-section creators-section row">
                    <div className="col-12">
                        <h2 className="app-title">WHO IS BEHIND TELLRY?</h2>
                        <img alt="Tellry Creators" src={require('../../../assets/images/about-creators.svg')} />
                        <p>Tellry is developed by a two-person team of a developer and a designer, this project is not sponsored or funded by anyone and we invested hundreds of hours of our own time because we believe it can grow into something great and benefit many creative people from around the world. Any support and criticism would be greatly appreciated!</p>
                    </div>
                </div>


            </div>
        );
    }
}

export default About;
