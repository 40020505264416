import React, {Component} from 'react';
import './StoriesRow.css';
import StoryItem from '../StoryItem/StoryItem';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

class StoriesRow extends Component{

    state = {
        title:'',
        rawStories:[],
        stories:[]
    };

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.stories.length > 0){
            let stories = [], i=0;
            for(let story of nextProps.stories){
                stories.push(<StoryItem story={story} dummy="false" link="" key={i} />);
                i++;
            }
            stories.push(<StoryItem story={nextProps.stories[0]} dummy="true" link={nextProps.link} key={nextProps.stories.length} />);
            return {title:nextProps.title,stories:stories};
        }
    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            dots: true,
            arrows:true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
            ]
        };

        const self = this;
        setTimeout(()=>{
            self.slider.slickGoTo(0);
        },500);

        return (
            <div className="row home-stories-row">
                <div className="col-9">
                    <h2>{this.state.title}</h2>
                </div>
                <div className="col-3">

                </div>
                <div className="col-12 story-sliders-col">
                    <div className="row stories-slider-row">
                        <Slider ref={slider => (this.slider = slider)} {...settings}>
                            {this.state.stories}
                        </Slider>
                        <img alt="Previous Slide" src={require('../../../../assets/images/arrow-left.svg')} className="center slider-arrow slider-arrow-left" onClick={this.previous} />
                        <img alt="Next Slide" src={require('../../../../assets/images/arrow-right.svg')} className="center slider-arrow slider-arrow-right" onClick={this.next} />
                    </div>
                </div>
            </div>
        );
    }
}

export default StoriesRow;
