import React, {Component} from 'react';
import axios from "axios";
import {connect} from "react-redux";
import StoriesRow from './StoriesRow/StoriesRow';
import './Library.css';
import SearchIcon from '../../../assets/images/search.svg';
import StoryItem from "./StoryItem/StoryItem";
import Slider from "react-slick";
import $ from 'jquery';

class Library extends Component{

    state = {
        mostReadStories:[],
        mostInteractedStories:[],
        recentlyPublished:[],
        loadingResultsFlag:false,
        showPageFlag:false,
        searchResultsFlag:false,
        genresPopFlag:false,
        searchVal:'',
        searchResults:[],
        urlGenres:['adventure', 'crime', 'drama', 'fantasy', 'horror', 'humor', 'mystery', 'non-fiction', 'romance', 'science-fiction', 'thriller', 'teen-novel'],
    };

    async componentDidMount() {
        await this.getMostRead();
        await this.getMostInteracted();
        await this.getRecentlyPublished();
    }

    getMostRead(){
        axios.post(`${process.env.REACT_APP_SERVER_IP}/custom-activities/top-read-stories`,{
            limit:25,
            skip:0
        }).then(
            (response)=>{
                //console.log("SUCCESS GETTING TOP READ",response);
                this.props.hideLoading();
                if(response.data.status===1){
                    this.setState({...this.state,mostReadStories:response.data.data});
                }else{

                }
            },
            (error)=>{
                this.props.hideLoading();
                //console.log("GET TOP READ ERROR",error);
            }
        );
    }

    getMostInteracted(){
        axios.post(`${process.env.REACT_APP_SERVER_IP}/custom-activities/top-interacted-stories`,{
            limit:25,
            skip:0
        }).then(
            (response)=>{
                console.log("SUCCESS GETTING TOP INTERACTED",response);
                this.props.hideLoading();
                if(response.data.status===1){
                    this.setState({...this.state,mostInteractedStories:response.data.data});
                }else{

                }
            },
            (error)=>{
                this.props.hideLoading();
                console.log("GET TOP INTERACTED ERROR",error);
            }
        );
    }

    getRecentlyPublished(){
        axios.post(`${process.env.REACT_APP_SERVER_IP}/custom-activities/recently-published-stories`,{
            limit:25,
            skip:0
        }).then(
            (response)=>{
                console.log("SUCCESS GETTING RECENTLY PUBLISHED",response);
                this.props.hideLoading();
                if(response.data.status===1){
                    this.setState({...this.state,recentlyPublished:response.data.data});
                }else{

                }
                this.setState({
                    ...this.state,
                    showPageFlag: true
                });
                setTimeout(function () {
                    $(".library-loading-wrap").hide();
                },1500);
            },
            (error)=>{
                this.props.hideLoading();
                console.log("GET RECENTLY PUBLISHED ERROR",error);
            }
        );
    }

    setSearchVal = (ev) => {
        this.setState({...this.state,searchVal:ev.target.value.substring(0,25)});
    }

    searchStory = (ev) => {
        if(!this.state.searchVal.trim()){
            return false;
        }
        else{
            this.setState({...this.state,loadingResultsFlag:true,searchResultsFlag:true})
            axios.post(`${process.env.REACT_APP_SERVER_IP}/custom-activities/by-title`,{
                title:this.state.searchVal.trim()
            }).then(
                (response)=>{
                    console.log("SUCCESS GETTING TOP READ",response);
                    this.props.hideLoading();
                    if(response.data.status===1) {
                        this.setState({...this.state, searchResults: response.data.data});
                    }
                    this.setState({...this.state, loadingResultsFlag: false});
                },
                (error)=>{
                    this.setState({...this.state, loadingResultsFlag: false});
                    console.log("GET TOP READ ERROR",error);
                }
            );
        }
    }

    hideSearchResults = () => {
        this.setState({
            ...this.state,
            searchResultsFlag:false,
            searchResults:[]
        });
    }

    openGenre(genre){
        this.props.history.push(`/library/${genre}`);
    }

    toggleGenresPop(){
        this.setState({...this.state,genresPopFlag:!this.state.genresPopFlag});
    }

    render() {
        const searchResults = this.state.searchResults.map((story,i)=>{
            return <div style={{ padding:'0px' }} className="col-3" key={i}><StoryItem story={story} /></div>;
        });

        const genres = this.state.urlGenres.map((genre,i)=>{
            return <span className="genre-item" onClick={()=>{ this.openGenre(genre) }} key={i}>{genre}</span>;
        });

        const filterGenres = this.state.urlGenres.map((genre,i)=>{
            const catImagePath = require(`../../../assets/images/cat-${ genre.toLowerCase().replace(/ /g, "-") }.svg`);
            return <div onClick={()=>{ this.openGenre(genre) }} key={i} className="lib-category-item">
                    <div className="lib-category-title">{ genre }</div>
                    <img alt={`${genre}`} src={catImagePath} />
                </div>;
        });

        const preloadGenres = this.state.urlGenres.map((genre,i)=>{
            const catImagePath = require(`../../../assets/images/cat-${ genre.toLowerCase().replace(/ /g, "-") }.svg`);
            return <img alt={`${genre}`} style={{ width:'1px', height:'1px', opacity:'0' }} src={catImagePath} />;
        });

        const genresSettings = {
            dots: false,
            arrows:false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 5,
            variableWidth: true
        };

        return (
            <div className="app-page site-stories-wrap lib-page-wrap">
                <div className="library-alpha-notice">
                    The Tellry library is still in alpha testing version, therefor you might see many gibberish stories here :)
                    <span className="hide-library-alpha-notice center">
                        <span className="center">x</span>
                    </span>
                </div>
                <div className="app-title lib-title">
                    <div className="lib-inner-title">
                        <div className="lib-web-title-div">LIBRARY</div>
                        <input onKeyUp={this.searchStory} value={this.state.searchVal} onChange={this.setSearchVal} style={{ backgroundImage: `url(${SearchIcon})`}} type="text" maxLength="25" placeholder="Find Story" />
                        <img alt="Filter Stories" onClick={()=>{this.toggleGenresPop()}} className="lib-filter-stories" src={require('../../../assets/images/filter.svg')} />
                        <div className="lib-mob-title-div">LIBRARY</div>
                    </div>
                    <div className="lib-genres-slider">
                        <Slider {...genresSettings}>
                            {genres}
                        </Slider>
                    </div>
                </div>
                <div className="library-inner-body">
                    <div className={ this.state.searchResultsFlag ? 'hide-story-sliders' : null }>
                        <StoriesRow stories={this.state.mostReadStories} title='MOST READ' link="most-read" />
                        <StoriesRow stories={this.state.mostInteractedStories} title='MOST INTERACTED' link="most-interacted" />
                        <StoriesRow stories={this.state.recentlyPublished} title='RECENTLY PUBLISHED' link="recently-published" />
                    </div>
                    {this.state.searchResultsFlag ? (<div className="library-search-results">
                        <h2 className="library-results-title">
                            <span>SEARCH RESULTS ({this.state.searchResults.length})</span>
                            <img alt="Close" onClick={this.hideSearchResults} src={require('../../../assets/images/close.svg')} />
                        </h2>
                        { this.state.searchResults.length && !this.state.loadingResultsFlag > 0 ? (<div className="row">{searchResults}</div>) : <div className="library-no-results center">NO STORIES FOUND</div> }
                        { this.state.loadingResultsFlag ?
                            (<div className="results-loading-wrap">
                                <img alt="Loading..." className="mob-lib-results-loading" src={require('../../../assets/images/lib-results-loading-mobile.svg')} />
                                <img alt="Loading..." className="web-lib-results-loading" src={require('../../../assets/images/lib-results-loading-web.svg')} />
                            </div>) : null }
                    </div>) : null}
                </div>
                <div className="library-loading-wrap">
                    <img alt="Loading..." className="library-loading-web" src={require('../../../assets/images/library-loading.svg')} />
                    <img alt="Loading..." className="library-loading-mob" src={require('../../../assets/images/library-loading-mob.svg')} />
                </div>
                { this.state.genresPopFlag ? (
                    <>
                    <div className="story-pop-overlay"></div>
                    <div className="library-filter">
                        <div onClick={()=>{this.toggleGenresPop()}} className="close-library-filter">
                            <img alt="Close" src={require('../../../assets/images/close.svg')} />
                        </div>
                        <div class="lib-filter-title">FILTER BY GENRE</div>
                        {filterGenres}
                    </div>
                    </>
                ) : null }
                <div>{preloadGenres}</div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLoading: ()=>{
            dispatch({
                type:'SHOW_LOADING'
            });
        },
        hideLoading: ()=>{
            dispatch({
                type:'HIDE_LOADING'
            });
        }
    }
};

export default connect(null,mapDispatchToProps)(Library);
