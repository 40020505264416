import React from 'react';
import {render} from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import reducer from './store/mainReducer';

const store = createStore(reducer);

render(<React.StrictMode><Provider store={store}><App /></Provider></React.StrictMode>, document.getElementById('root'));
