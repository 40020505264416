import React, {Component} from 'react';
import axios from 'axios';
import './ResetPassword.css';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

class ResetPassword extends Component{

    state = {
        code:'',
        errorFlag:false,
        expiredFlag:false,
        successFlag:false
    };

    componentDidMount(){
        document.title = `Reset Password`;
        this.props.showLoading();
        const params = this.props.match.params;

        if(!params.code){
            this.props.history.push('/');
        }else{
            axios.post(`${process.env.REACT_APP_SERVER_IP}/users/validate-reset-password`,{code:params.code}).then(
                (response)=>{
                    this.props.hideLoading();
                    if(response.data.status===1){
                        this.setState({...this.state,showPageFlag: true,successFlag:true})
                    }else if(response.data.status===3){
                        //Get story error
                        this.setState({...this.state,showPageFlag: true,expiredFlag:true})
                    }else{
                        this.setState({...this.state,errorFlag:true})
                    }

                    this.setState({...this.state, showPageFlag: true});
                },
                (error)=>{
                    this.props.hideLoading();
                    this.setState({...this.state,showPageFlag: true,errorFlag:true})
                }
            );

            this.props.hideLoading();

        }
    }

    renderContent() {
        if(this.state.successFlag){
            return <>
                <img alt="Reset Password Success" className="reset-password-error-img web" src={require('../../../assets/images/password-reset-success.svg')} />
                <img alt="Reset Password Success" className="reset-password-error-img mob" src={require('../../../assets/images/password-reset-success-mob.svg')} />
                <h2>YOUR PASSWORD WAS RESET!</h2>
                <p>We have reset your password! Please check your email for your temporary password, you can change it later in your profile area. <br/><br/> If you cant find the email make sure to look in the spam folder or promotions tab in gmail, if you are still experiencing difficulties - please  <Link to="/contact">Contact Us</Link></p>
            </>;
        }else if(this.state.expiredFlag){
            return <>
                <img alt="Reset Password Code Expired" className="reset-password-error-img web" src={require('../../../assets/images/code-expired.svg')} />
                <img alt="Reset Password Code Expired" className="reset-password-error-img mob" src={require('../../../assets/images/code-expired-mob.svg')} />
                <h2>CODE EXPIRED</h2>
                <p>Your reset code has expired, please use the App to renew the code. If you are still having problems please <Link to="/contact">Contact Us</Link></p>
            </>;
        }else if(this.state.errorFlag){
            return <>
                    <img alt="Reset Password Error" className="reset-password-error-img web" src={require('../../../assets/images/error.svg')} />
                    <img alt="Reset Password Error" className="reset-password-error-img mob" src={require('../../../assets/images/error-mob.svg')} />
                    <h2>WE COULD NOT PROCESS YOUR REQUEST</h2>
                    <p>Please try again or <Link to="/contact">Contact Us</Link></p>
                   </>;
        }else {
            return '';
        }
    }

    render () {
        return (
            <div className="app-page reset-password-wrap">
                {this.renderContent()}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLoading: ()=>{
            dispatch({
                type:'SHOW_LOADING'
            });
        },
        hideLoading: ()=>{
            dispatch({
                type:'HIDE_LOADING'
            });
        }
    }
};

export default connect(null,mapDispatchToProps)(ResetPassword);
