import React, {Component} from 'react';
import './StoryItem.css';
import moment from "moment";
import {Link} from 'react-router-dom';
import $ from 'jquery';
import Slider from "react-slick";

class StoryItem extends Component{
    state = {
        story:'',
        dummy:'false',
        link:'',
        timeAgo:'',
        intervalId:null
    };

    componentDidMount() {
        setTimeout(function (self) {
            self.refreshTimeAgo()
        }, 200, this)
        const intervalId = setInterval(function (self) {
            self.refreshTimeAgo()
        }, 60000, this)
        this.setState({...this.state,intervalId:intervalId});
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    getUserImage(user){
        switch(user['profile_image']){
            case 'local': return `${process.env.REACT_APP_S3_BUCKET}/uploads/users/${user._id}/profile.jpg`;
            case 'facebook': return `https://graph.facebook.com/${user.fb_id}/picture?width=200&height=200`;
            case 'woman_avatar': return require('../../../../assets/images/woman.svg');
            case 'man_avatar': default: return require('../../../../assets/images/man.svg');
        }
    }

    refreshTimeAgo(){
        if(this.state.story){
            const val = moment(this.state.story.published).fromNow();
            let splitVal = val.split(" "),
                newTimeAgo;

            if(val.includes('minute ')) {
                newTimeAgo =  '1m';
            }else if(val.includes('minutes')) {
                newTimeAgo =  splitVal[0]+'m';
            }else if(val.includes('hour ')) {
                newTimeAgo =  '1h';
            }else if(val.includes('hours')) {
                newTimeAgo =  splitVal[0]+'h';
            }else if(val.includes('day ')) {
                newTimeAgo =  '1d';
            }else if(val.includes('days')) {
                newTimeAgo =  splitVal[0]+'h';
            }else if(val.includes('month ')) {
                newTimeAgo =  '1mo';
            }else if(val.includes('months')) {
                newTimeAgo =  splitVal[0]+'mo';
            }else if(val.includes('year ')) {
                newTimeAgo =  '1y';
            }else if(val.includes('years')) {
                newTimeAgo =  splitVal[0]+'y';
            }else {
                newTimeAgo =  '1s'
            }

            this.setState({...this.state,timeAgo:newTimeAgo});
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.story){
            return {story: nextProps.story,dummy: nextProps.dummy, link:nextProps.link};
        }
    }

    shortSymvol(val,decimal){
        if(val>=1000){
            let x = (''+val).length,p=Math.pow,d=p(10,decimal);
            x-=x%3;
            return Math.round(val*d/p(10,x))/d+" kMGTPE"[x/3];
        }else{
            return val;
        }
    }

    showStorySynopsis(event){
        $(event.target).parents(".slide-inner").find(".slide-front").removeClass("flip-show").addClass("flip-hide");
        $(event.target).parents(".slide-inner").find(".slide-back").addClass("flip-show").removeClass("flip-hide");
    }

    hideStorySynopsis(event){
        $(event.target).parents(".slide-inner").find(".slide-front").addClass("flip-show").removeClass("flip-hide");
        $(event.target).parents(".slide-inner").find(".slide-back").removeClass("flip-show").addClass("flip-hide");
    }

    render() {
        const writersSliderSettings = {
            dots: false,
            arrows:false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: false
        };

        const writers = this.state.story.storyCredits.map((writer,i)=>{
            return (
                <div className="single-story-writer-name" key={i}>
                    <img alt={`${writer.firstname} ${writer.lastname[0]}`} src={this.getUserImage(writer)} />
                    <span>{writer.firstname} {writer.lastname[0]}</span>
                </div>
            );
        });

        return (
            <div className="slide-inner">
                <div className="slide-front">
                    <div className="col-12 story-item-col">
                        <article className="story-preview-wrap">
                            <header>
                                <Link to={`/story/${this.state.story._id}`}>
                                    <div className="story-image">
                                        <img alt={`${this.state.story.title} - story square preview`} className="story-square-image" src={`${process.env.REACT_APP_S3_BUCKET}/themes/${this.state.story['selectedTheme'].path}/preview.svg`} />
                                        <img alt={`${this.state.story.title} - story rect preview`} className="story-rect-image" src={`${process.env.REACT_APP_S3_BUCKET}/themes/${this.state.story['selectedTheme'].path}/rect.svg`} />
                                        <img className="square-preview" alt="" src={require('../../../../assets/images/square-preview.svg')} />
                                        <img className="rect-preview" alt="" src={require('../../../../assets/images/rect-preview.svg')} />
                                    </div>
                                    <h3 className="story-title">{this.state.story.title}</h3>
                                </Link>
                            </header>
                            <section className="story-body">
                                <div className="story-by">
                                    <span>Synopsis</span>
                                </div>
                                <div className="story-synopsis-preview">
                                    {this.state.story.synopsis.substring(0,200)}
                                </div>
                                <Link to={`/story/${this.state.story._id}`}><div className="story-synopsis-link story-synopsis-link-web">Read More</div></Link>
                                <div className="story-synopsis-link story-synopsis-link-mob" onClick={(e)=>{ this.showStorySynopsis(e); }}>Synopsis</div>
                            </section>
                            <footer className="story-footer">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="story-footer-item">
                                                    <img alt="Story Views" src={require('../../../../assets/images/small-story-views.svg')} />
                                                    <div>{this.shortSymvol(this.state.story.views,1)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="story-footer-item">
                                                    <img alt="Story Likes" src={require('../../../../assets/images/small-story-likes.svg')} />
                                                    <div>{this.shortSymvol(this.state.story.likesCount,1)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="story-footer-item">
                                                    <img alt="Story Comments" src={require('../../../../assets/images/small-story-comments.svg')} />
                                                    <div>{this.shortSymvol(this.state.story.comments,1)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="story-footer-item">
                                                    <img alt="Story Publish Date" src={require('../../../../assets/images/small-story-calendar.svg')} />
                                                    <div>
                                                        {this.state.timeAgo}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </footer>
                            { this.state.dummy==='true' ? (<Link to={`/library/${this.state.link}`}><div className="story-item-show-more"><div className="center">SHOW MORE</div></div></Link>) : null }
                        </article>
                        <article className="story-preview-results-wrap">
                            <div className="row">
                                <div className="col-4">
                                    <header>
                                        <Link to={`/story/${this.state.story._id}`}>
                                            <div className="story-image">
                                                <img alt={`${this.state.story.title} - story square preview`} className="story-square-image" src={`${process.env.REACT_APP_S3_BUCKET}/themes/${this.state.story['selectedTheme'].path}/preview.svg`} />
                                                <img alt={`${this.state.story.title} - story rect preview`} className="story-rect-image" src={`${process.env.REACT_APP_S3_BUCKET}/themes/${this.state.story['selectedTheme'].path}/rect.svg`} />
                                                <img className="square-preview" alt="" src={require('../../../../assets/images/square-preview.svg')} />
                                                <img className="rect-preview" alt="" src={require('../../../../assets/images/rect-preview.svg')} />
                                            </div>
                                            <h3 className="story-title">{this.state.story.title}</h3>
                                        </Link>
                                    </header>
                                </div>
                                <div className="col-8">
                                    <Link to={`/story/${this.state.story._id}`}>
                                        <h3 className="story-preview-results-title">{this.state.story.title}</h3>
                                    </Link>
                                    <div className="story-preview-results-synopsis">
                                        {this.state.story.synopsis.substring(0,155)}
                                        { this.state.story.synopsis.length > 155 ? (<span> [...]</span>) : '' }
                                    </div>
                                </div>
                                <div className="col-4">
                                    <footer className="story-footer">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <div className="story-footer-item">
                                                        <img alt="Story Views" src={require('../../../../assets/images/small-story-views.svg')} />
                                                        <div>{this.shortSymvol(this.state.story.views,1)}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="story-footer-item">
                                                        <img alt="Story Likes" src={require('../../../../assets/images/small-story-likes.svg')} />
                                                        <div>{this.shortSymvol(this.state.story.likesCount,1)}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="story-footer-item">
                                                        <img alt="Story Comments" src={require('../../../../assets/images/small-story-comments.svg')} />
                                                        <div>{this.shortSymvol(this.state.story.comments,1)}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="story-footer-item">
                                                        <img alt="Story Publish Date" src={require('../../../../assets/images/small-story-calendar.svg')} />
                                                        <div>
                                                            {this.state.timeAgo}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </footer>
                                </div>
                                <div className="col-8">
                                    <div className="story-writers-wrap">
                                        <Slider {...writersSliderSettings}>
                                            {writers}
                                            { this.state.story.storyCredits.length === 1 ? (
                                                <div className="single-story-writer-name hidden">
                                                    <img alt={`${this.state.story.storyCredits[0].firstname} ${this.state.story.storyCredits[0].lastname[0]}`} src={this.getUserImage(this.state.story.storyCredits[0])} />
                                                    <span>{this.state.story.storyCredits[0].firstname} {this.state.story.storyCredits[0].lastname[0]}</span>
                                                </div>
                                            ) : null }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <div className="slide-back">
                    <img alt="close" onClick={(e)=>{ this.hideStorySynopsis(e); }} src={require('../../../../assets/images/close.svg')} />
                    <div className="slide-back-title">SYNOPSIS</div>
                    <div className="slide-back-synopsis">{this.state.story.synopsis}</div>
                </div>
            </div>
        );
    }
}

export default StoryItem;
