import React, {Component} from 'react';
import './Comment.css';
import moment from "moment";
import axios from "axios";
import {connect} from "react-redux";

class Comment extends Component{
    state = {
        comment:'',
        timeAgo:'',
        intervalId:null
    };

    componentDidMount() {
        setTimeout(function (self) {
            self.refreshTimeAgo()
        }, 200, this)
        const intervalId = setInterval(function (self) {
            self.refreshTimeAgo()
        }, 60000, this)
        this.setState({...this.state,intervalId:intervalId});
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    refreshTimeAgo(){
        if(this.state.comment){
            const val = moment(this.state.comment.createdAt).fromNow();
            this.setState({...this.state,timeAgo:val});
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.comment){
            return {comment: nextProps.comment,preview: nextProps.preview};
        }
    }

    getUserImage(user){
        switch(user['profile_image']){
            case 'local':
                return `${process.env.REACT_APP_S3_BUCKET}/uploads/users/${user._id}/profile.jpg`;
            case 'facebook':
                return `https://graph.facebook.com/${user.fb_id}/picture?width=200&height=200`;
            case 'woman_avatar':
                return require('../../../../assets/images/woman.svg');
            case 'man_avatar': default:
                return require('../../../../assets/images/man.svg');
        }
    }

    loadMoreComments(){
        const id = this.state.comment.activityId,
              commentId = this.state.comment._id,
              skip  = this.state.comment.replies.length;

        this.props.showLoading();
        axios.post(`${process.env.REACT_APP_SERVER_IP}/custom-activities/get-comments`,{
            activity: id,
            skip: Math.floor(skip),
            action:'reply',
            comment: commentId
        }).then(
            (response)=>{
                //console.log("SUCCESS GETTING STORY REPLIES",response);
                this.props.hideLoading();
                if(response.data.status===1){
                    let comment = {...this.state.comment};

                    const oldReplies = [...this.state.comment.replies];
                    comment.replies = [...response.data.data.reverse(),...oldReplies]

                    if(response.data.data.length<20){
                        comment.repliesCount = comment.replies.length;
                    }

                    this.props.commentChangeHandler(comment)

                    this.setState({
                        ...this.state,
                        comment:comment
                    });
                }else{
                    //Get story error
                    //console.log("GET REPLIES ERROR");
                }
            },
            (error)=>{
                this.props.hideLoading();
                //console.log("GET REPLIES ERROR",error);
            }
        );
    }

    showRepliesPop(){
        this.props.repliesPopHandler(this.state.comment);
    }

    render() {
        let replies = [],repliesCount = 0;
        for(let reply of this.state.comment.replies){
            if(this.state.comment.replies.length <= 5 && repliesCount < 1){
                replies.push(<Comment comment={reply} key={repliesCount} />)
            }else if (this.state.comment.replies.length > 5){
                replies.push(<Comment comment={reply} key={repliesCount} />)
            }
            repliesCount++;
        }

        return (
            <div className="story-comment">
                <img alt={`${this.state.comment.by.firstname} ${this.state.comment.by.lastname[0]}`} className="story-comment-img" src={this.getUserImage(this.state.comment.by)} />
                <div className="story-comment-body">
                    <div className="comment-date">By {this.state.comment.by.firstname} {this.state.comment.by.lastname[0]}. | {this.state.timeAgo}
                    {this.state.comment.likesCount > 0 ? (<span className="comment-likes-wrap"><img alt="Like Story" src={require('../../../../assets/images/like-red.svg')} /> {this.state.comment.likesCount}</span>) : null}

                    </div>
                    <div className="story-comment-body-inner">
                        <span>{ this.state.comment.cont }</span>
                    </div>
                    {this.state.comment.repliesCount > 0 && !this.state.preview ? (<div onClick={()=>{this.showRepliesPop()}} className="replies-preview">{ this.state.comment.repliesCount } Replies</div>) : null}
                    <div className="replies-wrap">
                        {this.state.comment.repliesCount > this.state.comment.replies.length ? (<div onClick={()=>{this.loadMoreComments()}} className="load-more-replies">SHOW OLDER REPLIES</div>) : null}
                        {replies}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLoading: ()=>{
            dispatch({
                type:'SHOW_LOADING'
            });
        },
        hideLoading: ()=>{
            dispatch({
                type:'HIDE_LOADING'
            });
        }
    }
};

export default connect(null,mapDispatchToProps)(Comment);
