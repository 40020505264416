import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './PrivacyPolicy.css';
import {connect} from 'react-redux';

class PrivacyPolicy extends Component{

    state = {
        appState:false
    };

    componentDidMount() {
        document.title = `Tellry - Privacy Policy`;

        this.setState({
            appState:true
        });

        const appParam = new URLSearchParams(this.props.location.search).get("app");
        if(appParam === '1'){
            this.props.hideHeader();
        }
    }

    render(){
        return (
            <div className="app-page privacy-policy-wrap">
                <div className="row privacy-policy-inner-wrap">
                    <h2 className="app-title">PRIVACY POLICY</h2>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 privacy-policy-col">
                        <p className="last-update">Last Updated: 23.04.20</p>
                        <h3 className="first-title">What kinds of information do we collect?</h3>
                        <p>
                            <ul>
                                <li>
                                    <b>Personal Information - </b> We collect some personal information about you when you first register to the application\website. This includes required data like your Email Address and Full Name, or some optional information like your Age and Gender.
                                    The fields mentioned here are just and example, we may request more information about you in the future, either way - you are notified at register stage about ALL requied fields, no hidden data is collected.
                                </li>
                                <li>
                                    <b>Device/Network Information - </b> We collect some limited information about your mobile device\network such as your Device ID and IP Address.
                                </li>
                                <li>
                                    <b>Usage Information - </b> We collect information provided by you from you app usage - such as: The stories you create, your correspondence and communication with other users in the app\website.
                                </li>
                                <li>
                                    <b>Third Party Apps - </b> We collect information provided by external apps that you have given access to. For example - if you user facebook for login to our app, we will collect your personal data from facebook, all accordig to the permissions you provide.
                                </li>
                                <li>
                                    <b>Analytics data - </b> We collect analytics data about your app usage using trusted services such as Google Analytics
                                </li>
                            </ul>
                        </p>

                        <h3>How do we use this information?</h3>
                        <p>
                            <ul>
                                <li>
                                    <b>Personal Information - </b> We use your personal information to connect you to other writers and create a social experience for you and other app users. <b>We never share you information with any third parties.</b>
                                </li>
                                <li>
                                    <b>Device/Network Information - </b> We use your device/network information for security reasons - to validate\verify\block accounts. <b>We never share you information with any third parties.</b>
                                </li>
                                <li>
                                    <b>Usage Information - </b> We use your usage information to allow you and other users to communicate and make a social use of our systems. <b>We never access personal correspondences or stories - except in cases of reports of misconduct or inappropriate behaviour by a user - in this case we will inspect specific stories that are relevant to the report issue.</b>
                                </li>
                                <li>
                                    <b>Third Party Apps - </b> We use information provided by third party apps either co complete you personal information or allow you to share our app with you friends.
                                </li>
                                <li>
                                    <b>Analytics data - </b> We use analytics data to better target and advertise our application to users with similar interests to yours, in order to expand our users base.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h3>How is this information shared?</h3>
                        <p>
                            <ul>
                                <li>
                                    <b>We never share any of your information with any third parties.</b> The only information shared is the one you choose to share with other users in the app or out of it using the tools and systems provided by us.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h3>How can I manage or delete information about me?</h3>
                        <p>
                            <ul>
                                <li>
                                    You can choose at any time to delete you profile from our application/website. Whenever you choose to do so all your information will be permanently removed from the app including all your active activities and correspondences.
                                    However - Your completed and shared stories, and correspondences on them -  will not be removed from your co-writers account. Also, your account will not be fully removed from the application/website, it will remain empty and your name will be ranamed to "Anonymous User".
                                    This is because the stories you co-write with another user belong to them as well.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h3>How will we notify you of changes to this policy?</h3>
                        <p>
                            <ul>
                                <li>
                                    If our policy is changed you will be notified and be required to approve the new policy upon login to the app.
                                </li>
                            </ul>
                        </p>
                    </div>
                    { !this.state.appState ?
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <h3>How to contact us with questions</h3>
                            <p>
                                <ul>
                                    <li>
                                        You can always contact us through our contact pages both in the appliation and
                                        the website or directly by sending us an email:
                                        <div className="contact-links row">
                                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <a href="mailto:email@email.com">email@email.com</a>
                                            </div>
                                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <Link to="/contact">CONTACT US</Link>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </p>
                        </div>
                        : <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"><div className="policy-padding-dummy"></div></div> }
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        hideHeader: ()=>{
            dispatch({
                type:'HIDE_HEADER'
            });
        }
    }
};

export default connect(null,mapDispatchToProps)(PrivacyPolicy);
