import React,{Component} from 'react';
import { NavLink,Route } from 'react-router-dom';
import './Sidebar.css';
import {connect} from 'react-redux';
import DownloadAppBanner from "./DownloadAppBanner/DownloadAppBanner";

class Sidebar extends Component{

    state = {};

    render() {
        return (
            <div className={this.props.menuClass}>
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 main-sidebar-col">
                    <h1><NavLink className="row menu-row logo-menu-row" exact to="/" onMouseDown={this.props.onMenuToggle}><img alt="Tellry Logo" src={require('./../../../../assets/images/logo.svg')} /></NavLink></h1>
                    <div className="site-subtitle">
                        <span>Tell </span>
                        You<span>r </span>
                        Stor<span>y</span>
                    </div>

                    <NavLink className="row menu-row" exact to="/" onMouseDown={this.props.onMenuToggle}>
                        <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 side-icon-col">
                            <img alt="Download Tellry App" src={require('../../../../assets/images/phone-web-white.svg')} /></div>
                        <div className="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 side-link-col">
                            <span>DOWNLOAD APP</span>
                        </div>
                    </NavLink>
                    <NavLink className="row menu-row" exact to="/about" onMouseDown={this.props.onMenuToggle}>
                        <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 side-icon-col">
                            <img alt="About Tellry App" src={require('../../../../assets/images/about-home-white.svg')} /></div>
                        <div className="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 side-link-col">
                            <span>ABOUT</span>
                        </div>
                    </NavLink>
                    <NavLink className="row menu-row" exact to="/contact" onMouseDown={this.props.onMenuToggle}>
                        <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 side-icon-col">
                            <img alt="Contact Tellry" src={require('../../../../assets/images/contact-home-white.svg')} />
                        </div>
                        <div className="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 side-link-col">
                            <span>CONTACT US</span>
                        </div>
                    </NavLink>
                    <NavLink className="row menu-row" exact to="/privacy-policy" onMouseDown={this.props.onMenuToggle}>
                        <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 side-icon-col">
                            <img alt="Tellry Privacy Policy" src={require('../../../../assets/images/policy-home-white.svg')} /></div>
                        <div className="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 side-link-col">
                            <span>PRIVACY POLICY</span>
                        </div>
                    </NavLink>
                    <NavLink className="row menu-row" exact to="/terms" onMouseDown={this.props.onMenuToggle}>
                        <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 side-icon-col">
                            <img alt="Tellry Terms Of Service" src={require('../../../../assets/images/terms-home-white.svg')} /></div>
                        <div className="col-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 side-link-col">
                            <span>TERMS</span>
                        </div>
                    </NavLink>
                </div>

                <div className="close-app-sidebar" onMouseDown={this.props.onMenuToggle}>X</div>

                <Route path="/" exact component={DownloadAppBanner}/>
                <Route path="/privacy-policy" exact component={DownloadAppBanner}/>
                <Route path="/shared-story/" component={DownloadAppBanner}/>
                <Route path="/about/" exact component={DownloadAppBanner}/>
                <Route path="/contact/" exact component={DownloadAppBanner}/>
                <Route path="/about/" exact component={DownloadAppBanner}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        menuFlag: state.showMenuFlag,
        menuClass: state.showMenuClass
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onMenuToggle: ()=>{
            dispatch({
                type:'TOGGLE_MENU'
            });
        }
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Sidebar);
