import React,{Component} from 'react';
import {Router,Route,Redirect,Switch} from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import './App.css'
import Contact from './components/containers/Contact/Contact';
import DownloadApp from './components/containers/DownloadApp/DownloadApp';
import About from './components/containers/About/About';
import Sidebar from './components/containers/layout/Sidebar/Sidebar';
import Library from "./components/containers/Library/Library";
import SharedStory from "./components/containers/SharedStory/SharedStory";
import ResetPassword from "./components/containers/ResetPassword/ResetPassword";
import PrivacyPolicy from "./components/containers/PrivacyPolicy/PrivacyPolicy";
import Header from './components/containers/layout/Header/Header';
import Terms from "./components/containers/Terms/Terms";
import Story from "./components/containers/Story/Story";
import Category from "./components/containers/Category/Category";
import $ from 'jquery';

const history = createHistory();

class App extends Component {
    state = {};

    componentDidMount(){
        ReactGA.initialize('UA-165373315-1');
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);

        history.listen((location, action) => {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        });

        $(document).on("click",".hide-library-alpha-notice",function () {
            $(".library-alpha-notice").hide();
            $(".site-stories-wrap").removeClass("lib-page-wrap");
        });
    }

    render(){
        return(
            <Router history={history}>
                <div className="App">
                    <div className={"loading-overlay" + (this.props.loadingFlag ? ' show' : '') + " " + this.props.darkLoadingClass}>
                        <img alt="Loading..." className="center" src={require('./assets/images/loading.svg')} />
                    </div>
                    <div className="row">
                        { this.props.showHeaderFlag ? <Header /> : null }
                        { this.props.showHeaderFlag ?
                            <div className="col-2 col-xs-2 col-sm-2 col-md-3 col-lg-2 col-xl-2 app-sidebar-col">
                                <Sidebar />
                            </div>
                            : null }
                        <div className={ this.props.showHeaderFlag ? "col-12 col-xs-12 col-sm-12 col-md-9 col-lg-10 col-xl-10 site-body" : "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 site-body" }>
                            <Switch>
                                <Route path={process.env.PUBLIC_URL+'/'} exact component={DownloadApp} />
                                <Route path={process.env.PUBLIC_URL+'/reset-password/:code'} exact component={ResetPassword} />
                                <Route path={process.env.PUBLIC_URL+'/shared-story/:uid/:code'} exact component={SharedStory} />
                                <Route path={process.env.PUBLIC_URL+'/privacy-policy'} exact component={PrivacyPolicy} />
                                <Route path={process.env.PUBLIC_URL+'/contact'} exact component={Contact} />
                                <Route path={process.env.PUBLIC_URL+'/about'} exact component={About} />
                                <Route path={process.env.PUBLIC_URL+'/terms'} exact component={Terms} />
                                <Route path={process.env.PUBLIC_URL+'/library'} exact component={Library} />
                                <Route path={process.env.PUBLIC_URL+'/library/:category'} exact component={Category} />
                                <Route path={process.env.PUBLIC_URL+'/story/:id'} exact component={Story} />
                                <Route component={DownloadApp} />
                            </Switch>
                            {/*<Route render={() => <Redirect to="/"/>} />*/}
                        </div>
                    </div>
                    <div className="screen-test-div">
                        AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
                        AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
                        AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
                        AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
                        AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
                        AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
                        AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
                        AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
                    </div>
                </div>
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadingFlag: state.loadingFlag,
        showHeaderFlag: state.showHeaderFlag,
        darkLoadingClass: state.darkLoadingClass
    }
};

export default connect(mapStateToProps,null)(App);
