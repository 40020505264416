import React, {Component} from 'react';
import './Contact.css';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import {connect} from 'react-redux';

const recaptchaRef = React.createRef();

class Contact extends Component{

    state = {
        name:'',
        email:'',
        message:'',
        error:'',
        successFlag: false
    };

    changeNameHandler = (val) => {
        this.setState({
            ...this.state,
            name:val,
            successFlag:false
        });
    }

    changeEmailHandler = (val) => {
        this.setState({
            ...this.state,
            email:val,
            successFlag:false
        });
    }

    changeMessageHandler = (val) => {
        this.setState({
            ...this.state,
            message:val,
            successFlag:false
        });
    }

    setErrorHandler(val){
        this.setState({
            ...this.state,
            error:val,
            successFlag:false
        });
    }

    sendSuccessHandler(msg){
        document.getElementById('contact-name').value = '';
        document.getElementById('contact-email').value = '';
        document.getElementById('contact-message').value = '';
        this.setState({
            name:'',
            email:'',
            message:'',
            error:'',
            successFlag:true
        })
        this.setErrorHandler(msg);
    }

    sendMessageHandler = ()=>{
        this.props.showLoading();
        this.setErrorHandler('');
        recaptchaRef.current.execute();
        axios.post(`${process.env.REACT_APP_SERVER_IP}/main/contact`,{
            name:this.state.name,
            email:this.state.email,
            message:this.state.message
        }).then(
            (response)=>{
                this.props.hideLoading();
                console.log("SEND MSG SUCCESS", response)
                if(response.data.status===1){
                    this.sendSuccessHandler(response.data.msg);
                }else{
                    this.setErrorHandler(response.data.msg);
                    console.log("GET STORY ERROR");
                }
            },
            (error)=>{
                this.props.hideLoading();
                console.log("GET STORY ERROR 2",error);
            }
        );
    }

    render () {
        document.title = `Tellry - Contact Us`;

        return (
            <div className="app-page contact-page-wrap">
                <div className="inner-contact-form-wrap row">
                    <h2 className="app-title">CONTACT US</h2>
                    <div className="contact-form-col col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <fieldset className="form-wrap">
                            <div className="contact-input">
                                <input maxLength="50" id="contact-name" type="text" placeholder="Your Name" onKeyUp={(e)=>{this.changeNameHandler(e.target.value)}} required />
                                <span><img alt="User" src={require('./../../../assets/images/user.svg')} /></span>
                            </div>

                            <div className="contact-input">
                                <input maxLength="150" id="contact-email" type="email" placeholder="Your Email" onKeyUp={(e)=>{this.changeEmailHandler(e.target.value)}} required />
                                <span><img alt="Email" src={require('./../../../assets/images/email.svg')} /></span>
                            </div>

                            <div className="contact-input">
                                <textarea maxLength="400" id="contact-message" placeholder="Your Message" onKeyUp={(e)=>{this.changeMessageHandler(e.target.value)}} required></textarea>
                                <span><img alt="Message" src={require('./../../../assets/images/message-icon.svg')} /></span>
                            </div>

                            <div className={"contact-error"+ (this.state.successFlag ? ' success' : '')}>{this.state.error}</div>

                            <button className="submit-contact" onClick={this.sendMessageHandler}>SEND</button>
                        </fieldset>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 contact-image-col">
                        <img alt="Contact Us" src={require('./../../../assets/images/contact.svg')} />
                    </div>

                </div>

                <ReCAPTCHA sitekey="6Lcidu0UAAAAANHep2-XpRubWX7u0F9saGuwyqvw" ref={recaptchaRef}  size="invisible" />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLoading: ()=>{
            dispatch({
                type:'SHOW_LOADING'
            });
        },
        hideLoading: ()=>{
            dispatch({
                type:'HIDE_LOADING'
            });
        }
    }
};

export default connect(null,mapDispatchToProps)(Contact);
