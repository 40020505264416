import React, {Component} from 'react';
import axios from 'axios';
import './SharedStory.css';
import moment from 'moment';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

class SharedStory extends Component{

    state = {
        user1:{_id:0},
        user2:{_id:0},
        storyName:'',
        storyType:'',
        storyPublished:'',
        storyGenre:'',
        entries:[],
        views:0,
        showPageFlag:false,
        storyFoundFlag:false
    };

    componentDidMount(){
        this.props.showLoading();
        const params = this.props.match.params;

        axios.get(`${process.env.REACT_APP_SERVER_IP}/activities/get-published/${params.code}`).then(
            (response)=>{
                this.props.hideLoading();
                if(response.data.status===1){

                    const story = response.data.data,
                          user1 = story.user1 ? story.user1[0] : {_id:0},
                          user2 = story.user2 ? story.user2[0] : {_id:0};

                    if(params.uid===user1._id || params.uid===user2._id){
                        if((params.uid===user1._id && !story.user1_leave)  || (params.uid===user2._id && !story.user2_leave)) {

                            let storyPublished;
                            if(story.user1_publish || story.user2_publish){
                                storyPublished = params.uid === user1._id ? moment(story.user1_publish).fromNow() : moment(story.user2_publish).fromNow();
                            }else{
                                storyPublished = moment(story.createdAt).fromNow();
                            }

                            this.setState({
                                ...this.state,
                                user1:user1,
                                user2:user2,
                                storyName: params.uid === user1._id ? story.user1_publish_name : story.user2_publish_name,
                                storyType: story.type==='quick' ? 'Quick Mode' : 'Blind Mode',
                                storyPublished: storyPublished,
                                storyGenre:story.genre,
                                entries:story.entries,
                                views: story.sharedViews + story.profilePublishedViews,
                                createdAt: story.createdAt,
                                storyFoundFlag: true
                            });

                            document.title = `${this.state.storyName} - ${this.state.storyType} story Tellry App`;
                        }else{
                            //User left
                            //console.log("USER LEFT");
                        }
                    }else{
                        //User not matching
                        //console.log("USER NOT MATCHING STORY");
                    }
                }else{
                    //Get story error
                    //console.log("GET STORY ERROR");
                }
                this.setState({
                    ...this.state,
                    showPageFlag: true
                });
            },
            (error)=>{
                this.props.hideLoading();
                //console.log("GET STORY ERROR 2",error);
            }
        );
    }

    renderContent() {

        if(!this.state.showPageFlag){
            return null;
        }

        if (this.state.storyFoundFlag) {
            const entries = this.state.entries.map((entry,i) => {
                return <p className="single-story-entry" key={i}>{entry.full}</p>;
            });

            return <>
                <header className="single-story-header">
                    <img alt="Story Cover" className="single-web-bg" src={require('../../../assets/images/single-story-bg-1.png')}/>
                    <img alt="Story Cover" className="single-mob-bg" src={require('../../../assets/images/single-story-mobile.svg')}/>
                    <div className="single-story-header-overlay"></div>
                    <div className="single-story-header-wrap center">
                        <h1>{this.state.storyName}</h1>
                        <div className="single-story-by">
                            <span className="single-story-by-small">BY </span>
                            {this.state.user1._id!==0 ? <span>{this.state.user1.firstname} {this.state.user1.lastname}.</span> : null}
                            {(this.state.user1._id !==0 && this.state.user2._id!== 0) ? <span className="single-story-by-small">AND </span> : ''}
                            {this.state.user2._id!==0 ? <span>{this.state.user2.firstname} {this.state.user2.lastname}.</span> : null}
                        </div>
                        <div className="single-story-date">
                            <span>Published: </span>
                            <span>{this.state.storyPublished}</span>
                        </div>
                    </div>
                </header>
                <div className="single-story-body">
                    <h2>
                        <div className="single-story-type">{this.state.storyType}</div>
                        {this.state.type==='quick' ? <div className="single-story-genre">{this.state.storyGenre}</div> : null}
                    </h2>
                    <div className="single-story-entries-wrap">
                        {entries}
                        <div className="single-story-views">{this.state.views} views</div>
                    </div>
                </div>
            </>;
        }else{
            return <div className="story-not-found row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 snf-cont-col">
                    <div className="center snf-inner">
                        <h1>UH OH, THIS IS AWKWARD</h1>
                        <div>This story doesn't exist</div>
                        <Link className="app-button" exact to="/">TAKE ME HOME</Link>
                    </div>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 snf-image-col">
                    <img className="center" alt="STORY NOT FOUND" src={require('./../../../assets/images/story-404.svg')} />
                </div>
            </div>;
        }
    }

    render () {
        return (
            <div className="app-page single-story-wrap">
                {this.renderContent()}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLoading: ()=>{
            dispatch({
                type:'SHOW_LOADING'
            });
        },
        hideLoading: ()=>{
            dispatch({
                type:'HIDE_LOADING'
            });
        }
    }
};

export default connect(null,mapDispatchToProps)(SharedStory);
