import React,{Component} from 'react';
import { IoIosMenu } from "react-icons/io";
import {connect} from 'react-redux';
import './Header.css';

class Header extends Component{

    state = {};

    render(){
        return (
            <div className="row header-row">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" onMouseDown={this.props.onMenuToggle}>
                    <IoIosMenu className="menu-icon" />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <span className="logo-name">Tellry</span>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMenuToggle: ()=>{
            dispatch({
                type:'TOGGLE_MENU'
            });
        }
    }
};

export default connect(null,mapDispatchToProps)(Header);