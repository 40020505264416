import React, {Component} from 'react';
import './DownloadApp.css';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";

class DownloadApp extends Component{

    state = {
        underConstructionFlag:false
    };

    componentDidMount() {
        document.title = `Download the Tellry App`;
    }

    underConstructionToggleHandler = (action) => {
        if(action==='show'){
            this.setState({underConstructionFlag: true});
            this.props.showLoading();
        }else{
            this.setState({underConstructionFlag: false});
            this.props.hideLoading();
        }
    }

    render () {
        let underConstructionPop = '';
        if (this.state.underConstructionFlag) {
            underConstructionPop = (
                <>
                    <div className="under-construction-pop center">
                        <img onClick={ ()=> this.underConstructionToggleHandler('hide') } alt="Close Popup" className="close-construction-pop" src={require('../../../assets/images/close.svg')}/>
                        <img alt="Under Constructions" className="construction-img" src={require('../../../assets/images/under-construction.png')}/>
                        <div className="construction-desc">Tellry has just began Alpha stage testing! An open beta is planned by the end of 10.20 and hopefully we expect a production version to be live before the end of 2020.
                            <br/><br/> If you own an Android device and would like to become an alpha tester - please check out our facebook testing group</div>
                        <a href="https://www.facebook.com/groups/818701652002557" target="_blank" rel="noopener noreferrer" className="app-button">ALPHA TESTING</a>
                    </div>
                </>
            );
        }


        return (
            <div className="download-app-wrap app-page">
                <img alt="Download App Header" className="da-img-web" src={require('../../../assets/images/download-web.svg')} />
                <img alt="Download App Header" className="da-img-mob" src={require('../../../assets/images/download-mob.svg')} />
                <div className="da-page-inner row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        <div className="da-welcome-title">Hello! :)</div>
                        <div className="da-info">
                            <div className="da-info-first">Tellry is a social story writing mobile app. Where you can write stories with your friends or be quickly matched with random writers from around the world.</div>
                            <div>There are various story modes available, and we are working hard on new features as you read this.</div>
                            <div>We hope you will enjoy using our app, please <Link to="/about">read more about our app</Link> and feel free to <Link to="/contact">contact us</Link> on any issues :)</div>
                        </div>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="da-buttons-wrap">
                            <div>
                                <img alt="Download Tellry For ANDROID" onClick={ ()=> this.underConstructionToggleHandler('show') } src={require('../../../assets/images/android.svg')} />
                            </div>
                            <div>
                                <img alt="Download Tellry For IOS" onClick={ ()=> this.underConstructionToggleHandler('show') } src={require('../../../assets/images/ios.svg')} />
                            </div>
                        </div>
                    </div>
                </div>

                {underConstructionPop}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLoading: ()=>{
            dispatch({
                type:'SHOW_DARK_LOADING'
            });
        },
        hideLoading: ()=>{
            dispatch({
                type:'HIDE_LOADING'
            });
        }
    }
};

export default connect(null,mapDispatchToProps)(DownloadApp);
