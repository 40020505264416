import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import './DonloadAppBanner.css';
import {connect} from 'react-redux';

class DownloadAppBanner extends Component{
    render(){
        return (
            <Link className="download-app-banner-wrap" to="/app" onMouseDown={this.props.onMenuToggle}>
                <img alt="Download Tellry Application" className="download-app-banner" src={require("../../../../../assets/images/download-app-mobile.svg")} />
            </Link>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMenuToggle: ()=>{
            dispatch({
                type:'TOGGLE_MENU'
            });
        }
    }
};

export default connect(null,mapDispatchToProps)(DownloadAppBanner);