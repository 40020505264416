const initialState = {
    loadingFlag: false,
    showMenuFlag: false,
    showMenuClass: 'row main-sidebar align-items-start',
    darkLoadingClass: 'dark',
    showHeaderFlag: true
};

const reducer = (state = initialState,action) =>{
    switch(action.type){
        case 'TOGGLE_MENU':
            if(state.showMenuFlag){
                return {
                    ...state,
                    showMenuFlag: false,
                    showMenuClass: 'row main-sidebar align-items-start hidden'
                };
            }else{
                return {
                    ...state,
                    showMenuFlag: true,
                    showMenuClass: 'row main-sidebar align-items-start active'
                };
            }
        case 'SHOW_LOADING':
            return {
                ...state,
                loadingFlag: true
            }
        case 'SHOW_DARK_LOADING':
            return {
                ...state,
                loadingFlag: true,
                darkLoadingClass: ' dark'
            }
        case 'HIDE_LOADING':
            return {
                ...state,
                loadingFlag: false,
                darkLoadingClass: ''
            }
        case 'HIDE_HEADER':
            return {
                ...state,
                showHeaderFlag: false
            }
        default: return state;



    }
}

export default reducer;