import React, {Component} from 'react';
import './Story.css';
import moment from "moment";
import {Link} from "react-router-dom";
import axios from "axios";
import {connect} from "react-redux";
import $ from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Comment from "./Comment/Comment";
import ReactHtmlParser from 'react-html-parser';
import queryString from 'query-string';

class Story extends Component{

    constructor () {
        super();
        this.commentChangeHandler = this.commentChangeHandler.bind(this);
        this.toggleRepliesPop = this.toggleRepliesPop.bind(this);
    }

    state = {
        story:'',
        timeAgo:'',
        intervalId:null,
        storyFoundFlag:false,
        pageSize:0,
        pages:[],
        currentPage:1,
        firstRender:false,
        changePagePopFlag:false,
        showCommentsPopFlag:false,
        showRepliesPopFlag:false,
        unpublishedFlag:false,
        selectedComment:null
    };

    componentDidMount() {
        this.props.showLoading();
        const params = this.props.match.params;
        const intervalId = setInterval(function (self) {
            self.refreshTimeAgo()
        }, 60000, this)
        this.setState({...this.state,intervalId:intervalId});
        this.getStory(params.id);
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    next() {
        this.pagesSlider.slickNext();
        //
        // const nextPage =
        // this.setState({...this.state,currentPage:this.state.currentPage++});
    }
    previous() {
        this.pagesSlider.slickPrev();
    }

    initChangePage(){
        const newVal = !this.state.changePagePopFlag;
        this.setState({...this.state,changePagePopFlag:newVal});
        setTimeout(function(){
            $(".choose-story-page input").focus();
            $(".choose-story-page input").select();
        },200);
    }

    setPage(){
        const val = Math.floor($(".choose-story-page input").val());
        let newVal = 0;
        if(val < 0){
            newVal = 1;
        }else if(val>this.state.pages.length){
            newVal = this.state.pages.length;
        }else if(isNaN(val)){
            return false;
        }else{
            newVal = val-1;
        }
        this.initChangePage();
        this.pagesSlider.slickGoTo(newVal);
    }

    refreshTimeAgo(){
        if(this.state.story){
            const val = moment(this.state.story.published).fromNow();
            let splitVal = val.split(" "),
                newTimeAgo;

            if(val.includes('minute ')) {
                newTimeAgo =  '1m';
            }else if(val.includes('minutes')) {
                newTimeAgo =  splitVal[0]+'m';
            }else if(val.includes('hour ')) {
                newTimeAgo =  '1h';
            }else if(val.includes('hours')) {
                newTimeAgo =  splitVal[0]+'h';
            }else if(val.includes('day ')) {
                newTimeAgo =  '1d';
            }else if(val.includes('days')) {
                newTimeAgo =  splitVal[0]+'h';
            }else if(val.includes('month ')) {
                newTimeAgo =  '1mo';
            }else if(val.includes('months')) {
                newTimeAgo =  splitVal[0]+'mo';
            }else if(val.includes('year ')) {
                newTimeAgo =  '1y';
            }else if(val.includes('years')) {
                newTimeAgo =  splitVal[0]+'y';
            }else {
                newTimeAgo =  '1s'
            }

            this.setState({...this.state,timeAgo:newTimeAgo});
        }
    }

    getPageSize(){
        let decreaseVal = 0;
        if($(window).width()<=768){
            decreaseVal = 200;
        }else{
            decreaseVal = 200;
        }

        const self = this;
        while($('.screen-test-div')[0].scrollHeight ===  $('.screen-test-div')[0].offsetHeight) {
            $(".screen-test-div").append('AAAAAAAAAAAAAAA');
            if ($('.screen-test-div')[0].scrollHeight !== $('.screen-test-div')[0].offsetHeight || $(".screen-test-div").text().length >= 1300) {
                self.setState({
                    ...this.state,
                    pageSize: $('.screen-test-div').text().length - (decreaseVal*2),
                    showPageFlag: true
                });
                setTimeout(function () {
                    $(".library-loading-wrap").hide();
                },1500);
                self.generatePages();
                break;
            }
            $("#custom-story-body").css('max-height', $(window).height() - 100);
        }
    }

    generatePages(){
        const regexParam = `.{1,${this.state.pageSize}}`,
            regex = new RegExp(regexParam,'g');
        let longText='',counter=0;
        for(let entry of this.state.story.displayEntries){
            longText+=entry;
            if(counter!==0){
                longText+='</br>';
            }
            counter++;
        }
        this.setState({
            ...this.state,
            pages:longText.match(regex)
        });

        if(!this.state.firstRender){
            this.setState({...this.state,firstRender:true});
            const self = this;
            setTimeout(()=>{
                self.pagesSlider.slickGoTo(0);
            },500);
        }
    }

    getStory(id){
        axios.get(`${process.env.REACT_APP_SERVER_IP}/custom-activities/get-published/${id}`).then(
            (response)=>{
                //console.log("SUCCESS GETTING STORY",response);
                this.props.hideLoading();
                if(response.data.status===1){
                    let story = response.data.data;
                    if(story.comments instanceof Array){
                        story.comments.reverse();
                        for(let comment of story.comments){
                            if(comment.replies instanceof Array){
                                comment.replies.reverse();
                            }
                        }
                    }

                    this.setState({
                        ...this.state,
                        storyFoundFlag:true,
                        story:story
                    });
                    this.reportGA();
                    this.refreshTimeAgo();
                    this.getPageSize();
                }else{
                    //Get story error
                    //console.log("GET STORY ERROR");
                }
            },
            (error)=>{
                this.props.hideLoading();
                //console.log("GET STORY ERROR 2",error);
            }
        );
    }

    reportGA(){
        let uuid = localStorage.getItem('uuid');
        if(!uuid){
            uuid = this.generateUuid();
            localStorage.setItem('uuid',uuid);
        }

        const dataSource = 'web',
            event = 'screenview',
            page = 'story',
            screenTitle = 'Story',
            screenUrl = '/story',
            linkId = this.state.story._id,
            eventAction = this.state.story._id,
            eventCategory = 'ECC',
            googleAdsId = process.env.REACT_APP_ANALYTICS_ADS,
            screenResolution = `${$(window).width()}X${$(window).height()}`;
        axios.post(`https://www.google-analytics.com/collect`,queryString.stringify({
            v: '1',
            tid: process.env.REACT_APP_ANALYTICS_ID,
            cid: uuid,
            t: event,
            dp: page,
            ds: dataSource,
            gclid: googleAdsId,
            sr: screenResolution,
            dl: screenUrl,
            dt: screenTitle,
            cd: linkId,
            linkid: linkId,
            an: 'Tellry',
            ec: eventCategory,
            ea: eventAction
        }),{
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(
            (response)=>{},
            (error)=>{}
        );
    }

    changePage(i){
        this.setState({...this.state,currentPage:i+1});
        this.pagesSlider.slickGoTo(i+1,false);
    }

    loadMoreComments(){
        const id = this.state.story._id,
              skip  = this.state.story.comments.length;

        this.props.showLoading();
        axios.post(`${process.env.REACT_APP_SERVER_IP}/custom-activities/get-comments`,{
            activity: id,
            skip: Math.floor(skip),
            action:'comment',
            comment: ''
        }).then(
            (response)=>{
                //console.log("SUCCESS GETTING STORY COMMENTS",response);
                this.props.hideLoading();
                if(response.data.status===1){
                    let story = {...this.state.story};

                    const oldComments = [...this.state.story.comments];
                    story.comments = [...response.data.data.reverse(),...oldComments]

                    if(response.data.data.length<20){
                        story.commentsCount = story.comments.length;
                    }

                    this.setState({
                        ...this.state,
                        story:story
                    });
                }else{
                    //Get story error
                    //console.log("GET COMMENTS ERROR");
                }
            },
            (error)=>{
                this.props.hideLoading();
                //console.log("GET COMMENTS ERROR",error);
            }
        );
    }

    commentChangeHandler(val){

        let story = {...this.state.story};
        const commentIndex = story.comments.findIndex(x => x._id === val._id);
        if(commentIndex > -1){
            story.comments[commentIndex] = val;
            this.setState({...this.state,story:story});
        }
    }

    shortSymvol(val,decimal){
        if(val>=1000){
            let x = (''+val).length,p=Math.pow,d=p(10,decimal);
            x-=x%3;
            return Math.round(val*d/p(10,x))/d+" kMGTPE"[x/3];
        }else{
            return val;
        }
    }

    toggleCommentsPop(){
        if($(window).width() <= 768){
            const newVal = !this.state.showCommentsPopFlag;
            this.setState({...this.state,showCommentsPopFlag:newVal});
        }
    }

    toggleRepliesPop(comment){
        if($(window).width() <= 768){
            const newVal = !this.state.showRepliesPopFlag;
            this.setState({...this.state,showRepliesPopFlag:newVal,selectedComment:comment});
        }
    }

    hideRepliesPop(){
        this.setState({...this.state,showRepliesPopFlag:false,selectedComment:null});
    }

    loadMoreReplies(){
        const id = this.state.story._id,
            commentId = this.state.selectedComment._id,
            skip  = this.state.selectedComment.replies.length;

        this.props.showLoading();
        axios.post(`${process.env.REACT_APP_SERVER_IP}/custom-activities/get-comments`,{
            activity: id,
            skip: Math.floor(skip),
            action:'reply',
            comment: commentId
        }).then(
            (response)=>{
                //console.log("SUCCESS GETTING STORY REPLIES",response);
                this.props.hideLoading();
                if(response.data.status===1){
                    let comment = {...this.state.selectedComment};

                    const oldReplies = [...this.state.selectedComment.replies];
                    comment.replies = [...response.data.data.reverse(),...oldReplies]

                    if(response.data.data.length<20){
                        comment.repliesCount = comment.replies.length;
                    }

                    this.setState({
                        ...this.state,
                        selectedComment:comment
                    });
                }else{
                    //Get story error
                    //.log("GET REPLIES ERROR");
                }
            },
            (error)=>{
                this.props.hideLoading();
                //console.log("GET REPLIES ERROR",error);
            }
        );
    }

    renderContent() {

        const settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            afterChange: (next) =>{
                this.setState({...this.state,currentPage:next+1});
            },
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
            ]
        };

        if(!this.state.showPageFlag){
            return null;
        }

        if (this.state.storyFoundFlag) {
            const pages = this.state.pages.map((entry,i) => {
                return <div className="custom-story-page" key={i}>{ReactHtmlParser(entry)}</div>;
            });

            let genresCounter=0,genresKeyCounter=0, genres = [];
            for(let genre of this.state.story.genres){
                genres.push(<span key={genresKeyCounter} data-test={genresKeyCounter} className="custom-story-genre">{genre.name}</span>);
                if(genresCounter!==this.state.story.genres.length-1){
                    genresKeyCounter++;
                    genres.push(<span key={genresKeyCounter}  data-test={genresKeyCounter}>, </span>);
                }
                genresCounter++;
                genresKeyCounter++;
            }

            let maturityCounter=0,maturityKeyCounter=0,maturity = [];
            for(let maturityVal of this.state.story.maturityValues){
                maturity.push(<span key={maturityKeyCounter} className="custom-story-maturity">{maturityVal}</span>);
                if(maturityCounter!==this.state.story.maturityValues.length-1){
                    maturityKeyCounter++;
                    maturity.push(<span key={maturityKeyCounter} className="custom-story-maturity"> &bull; </span>);
                }
                maturityCounter++;
                maturityKeyCounter++;
            }

            let pagination = [];
            for(let i=0; i<this.state.pages.length; i++){
                let active = '';
                if(i===this.state.currentPage-1){active='active';}
                pagination.push(<span onClick={() => this.changePage(i)} className={`custom-story-pagination-item ${active}`} key={i}><span className="center">{i+1}</span></span>);
            }

            const comments = this.state.story.comments.map((comment,i) => {
                return <Comment repliesPopHandler={this.toggleRepliesPop} commentChangeHandler={this.commentChangeHandler} comment={comment} key={i} />;
            });

            let replies = [];
            if(this.state.selectedComment){
                replies = this.state.selectedComment.replies.map((comment,i) => {
                    return <Comment comment={comment} key={i+1} />;
                });
            }

            return <>
                <header className="custom-story-header">
                    <img alt="Story Cover" className="custom-web-bg" src={`${process.env.REACT_APP_S3_BUCKET}/themes/${this.state.story['selectedTheme'].path}/web.svg`} />
                    <img alt="Story Cover" className="custom-mob-bg" src={`${process.env.REACT_APP_S3_BUCKET}/themes/${this.state.story['selectedTheme'].path}/mobile.svg`} />
                    <div className="custom-story-header-overlay"></div>
                    <div className="custom-story-lib-link">
                        <img alt="Library Icon" src={require('../../../assets/images/white-lib.svg')} />
                        <span className="center"><Link to="/library">TO LIBRARY</Link></span>
                    </div>
                    <div className="custom-story-header-wrap center">
                        <h1>{this.state.story.title}</h1>
                        <div className="custom-story-title-meta">
                          <span className="custom-story-title-meta-item">
                                <img alt="Story Views" src={require('../../../assets/images/white-eye.svg')} />
                              <span>{this.shortSymvol(this.state.story.views + 1,1)}</span>
                          </span>
                          <span className="custom-story-title-meta-item">
                              <img alt="Story Likes" src={require('../../../assets/images/white-heart.svg')} />
                              <span>{this.shortSymvol(this.state.story.likesCount,1)}</span>
                          </span>
                          <span className="custom-story-title-meta-item">
                              <img alt="Story Comments" src={require('../../../assets/images/white-comment.svg')} />
                              <span>{this.shortSymvol(this.state.story.commentsCount,1)}</span>
                          </span>
                          <span className="custom-story-title-meta-item">
                              <img alt="Story Publish Date" src={require('../../../assets/images/white-calendar.svg')} />
                              <span>{this.state.timeAgo}</span>
                          </span>
                        </div>
                    </div>
                </header>
                <div id="custom-story-body" className="custom-story-body">
                    <div className="custom-story-genres">
                        <div>{genres}</div>
                        <div>{maturity}</div>
                    </div>
                    <div className="custom-story-entries-wrap">
                        <Slider ref={slider => (this.pagesSlider = slider)} {...settings}>
                            {pages}
                        </Slider>
                        <div className="custom-story-pagination">
                            <div className="custom-story-pagination-inner">
                                {pagination}
                            </div>
                        </div>
                        <div className="story-swipe-info">
                            <img alt="Story Swipe" className="center sign-ra" src={require('../../../assets/images/story-right-arrow-sign.svg')} />
                            <img alt="Story Swipe" className="center sign-la" src={require('../../../assets/images/story-left-arrow-sign.svg')} />
                            <div>
                                <span onClick={()=>{this.initChangePage()}} className="story-current-page">{this.state.currentPage}</span> / <span className="story-total-pages">{this.state.pages.length}</span>
                            </div>
                            <div className="story-swipe-note">SWIPE TEXT TO CHANGE PAGE</div>
                        </div>
                        { this.state.currentPage!==1 ? (<img alt="Previous Page" onClick={()=>{this.previous()}} className="story-pages-arrow story-pages-arrow-left" src={require('../../../assets/images/story-page-left.svg')} />) : '' }
                        { this.state.currentPage!==this.state.pages.length ? (<img alt="Next Page" onClick={()=>{this.next()}} className="story-pages-arrow story-pages-arrow-right" src={require('../../../assets/images/story-page-right.svg')} />) : '' }
                    </div>
                    <div className="custom-story-sep"></div>
                    <div className="custom-story-comments-wrap">
                        <div onClick={()=>{this.toggleCommentsPop()}} className="custom-story-comments-title">{this.state.story.commentsCount} comments</div>
                        <div className="custom-story-comments-body">
                            {this.state.story.commentsCount > this.state.story.comments.length ? (<div onClick={()=>{this.loadMoreComments()}} className="load-more-comments">SHOW OLDER COMMENTS</div>) : null}
                            {comments}
                        </div>
                    </div>
                </div>
                <div className="lib-banner">
                    <Link to="/library">
                        <img alt="Open Tellry Library" src={require('../../../assets/images/lib-banner.svg')} />
                        <div className="center">DISCOVER MORE STORIES IN THE TELLY LIBRARY</div>
                    </Link>
                </div>
                { this.state.showCommentsPopFlag && !this.state.unpublishedFlag ? (
                    <>
                        <div className="story-comments-pop">
                            <div className="comments-title" onClick={()=>{this.toggleCommentsPop()}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="s-ion-icon"><path d="M427 234.625H167.296l119.702-119.702L256 85 85 256l171 171 29.922-29.924-118.626-119.701H427v-42.75z"></path></svg>
                                <span>{this.state.story.commentsCount} COMMENTS</span>
                            </div>
                            <div id="comments-body" className="comments-body">
                                {this.state.story.commentsCount > this.state.story.comments.length ? (<div onClick={()=>{this.loadMoreComments()}} className="load-more-comments">SHOW OLDER COMMENTS</div>) : '' }
                                {comments}
                            </div>
                        </div>
                    </>
                ) : null }
                { this.state.showRepliesPopFlag && !this.state.unpublishedFlag ? (
                    <>
                        <div className="story-comments-pop replies-pop">
                            <div className="comments-title" onClick={()=>{this.hideRepliesPop()}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="s-ion-icon"><path d="M427 234.625H167.296l119.702-119.702L256 85 85 256l171 171 29.922-29.924-118.626-119.701H427v-42.75z"></path></svg>
                                <span>{this.state.selectedComment.repliesCount} REPLIES</span>
                            </div>
                            <div id="comments-body" className="comments-body">
                                <Comment comment={this.state.selectedComment} key="0" preview="true" />
                                {this.state.selectedComment.repliesCount > this.state.selectedComment.replies.length ? (<div onClick={()=>{this.loadMoreReplies()}} className="load-more-comments">SHOW OLDER REPLIES</div>) : '' }
                                <div className="replies-inner">
                                    {replies}
                                </div>
                            </div>
                        </div>
                    </>
                ) : null }
                { this.state.changePagePopFlag ? (
                    <>
                        <div className="story-pop-overlay"></div>
                        <div className="center choose-story-page">
                            <img alt="Close" onClick={()=>{this.initChangePage()}} src={require('../../../assets/images/close.svg')} />
                            <input maxLength="6" pattern="\d*" type="tel" defaultValue={this.state.currentPage} />
                            <div onClick={()=>{this.setPage()}} className="app-button">CHANGE PAGE</div>
                        </div>
                    </>
                ) : null}
            </>;
        }else{
            return <div className="story-not-found row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 snf-cont-col">
                    <div className="center snf-inner">
                        <h1>UH OH, THIS IS AWKWARD</h1>
                        <div>This story doesn't exist</div>
                        <Link className="app-button" exact to="/">TAKE ME HOME</Link>
                    </div>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 snf-image-col">
                    <img className="center" alt="STORY NOT FOUND" src={require('./../../../assets/images/story-404.svg')} />
                </div>
            </div>;
        }
    }

    generateUuid() {
        let lut = [];
        for (var i=0; i<256; i++) { lut[i] = (i<16?'0':'')+(i).toString(16); }

        let d0 = Math.random()*0xffffffff|0,
            d1 = Math.random()*0xffffffff|0,
            d2 = Math.random()*0xffffffff|0,
            d3 = Math.random()*0xffffffff|0;

        return lut[d0&0xff]+lut[d0>>8&0xff]+lut[d0>>16&0xff]+lut[d0>>24&0xff]+'-'+
            lut[d1&0xff]+lut[d1>>8&0xff]+'-'+lut[d1>>16&0x0f|0x40]+lut[d1>>24&0xff]+'-'+
            lut[d2&0x3f|0x80]+lut[d2>>8&0xff]+'-'+lut[d2>>16&0xff]+lut[d2>>24&0xff]+
            lut[d3&0xff]+lut[d3>>8&0xff]+lut[d3>>16&0xff]+lut[d3>>24&0xff];
    }

    render () {
        return (
            <div className="app-page custom-story-wrap">
                {this.renderContent()}
                <div className="library-loading-wrap">
                    <img alt="Loading..." className="library-loading-web" src={require('../../../assets/images/story-loading.svg')} />
                    <img alt="Loading..." className="library-loading-mob" src={require('../../../assets/images/story-loading-placeholder-mob.svg')} />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLoading: ()=>{
            dispatch({
                type:'SHOW_LOADING'
            });
        },
        hideLoading: ()=>{
            dispatch({
                type:'HIDE_LOADING'
            });
        }
    }
};

export default connect(null,mapDispatchToProps)(Story);
